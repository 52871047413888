/* You can add global styles to this file, and also import other style files */
//@import "~ngx-sharebuttons/themes/default/default-theme.scss";
@import "~@ctrl/ngx-emoji-mart/picker";

.product__inner .carousel-control-prev {
  display: none !important;
}
.product__inner .carousel-control-next {
  display: none !important;
}
::ng-deep.cdk-global-scrollblock{
  position: absolute !important;
  left: 0px !important;
  top: 270px !important;
}

.product__inner .carousel-indicators {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 150px;
  color: #fff;
  text-align: center;
  flex-direction: column;
  margin: 0px !important;
  left: 0;
}
.product__inner .carousel-inner {
  padding-left: 160px;
  text-align: center;
}
.product__inner .carousel .carousel-indicators li {
  height: 140px !important;
  width: 140px !important;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 0 3px 5px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.cdk-global-scrollblock{
  left: 0px !important;
  top: 0px !important;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

@import "~bootstrap/scss/bootstrap";
.mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
  font: 400 16px / 20px Roboto , "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  
}
// .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
//   position: fixed;
//   width: 100%;
//   height: 120%;
//   // z-index: 11010;
//   top: 0;
//   left: 0;
//   -webkit-animation: component__fadeInNew 1s forwards;
//   animation: component__fadeInNew 1s forwards;

//   // background-color: rgb(85, 56, 56);
// }
.mat-tooltip {
  max-width: 500px !important; 
  margin: 5px !important;       
  color: black !important;      
  background-color: white !important; 
  border-radius: 4px;          
  padding: 12px;                
  font-size: 14px;             
}
