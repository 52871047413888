@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~leaflet/dist/leaflet.css";
@import '~swiper/swiper-bundle.min.css';
@import "https://naver.github.io/egjs-view360/release/latest/css/view360.min.css";
@import "~pannellum/build/pannellum.css";
@import "ngx-toastr/toastr";




/*@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,900&display=swap');
/* #Primary
================================================== */
body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #212112;
  background-position: center;
  background-repeat: repeat;
  background-size: 7%;
  background-color: #fff;
  overflow-x: hidden;
  transition: all 200ms linear;
  margin: 0;
  padding: 0;
  height: 100%;
}
html {
  height: 100%;
}
::selection {
  color: #fff;
  background-color: #004aad;
}
::-moz-selection {
  color: #fff;
  background-color: #004aad;
}
.no-gutter {
  margin: 0;
  padding: 0;
}
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:after,
:before {
  box-sizing: border-box;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  display: block;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  color: inherit;
  text-decoration: none;
}
/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(114, 206, 229, 0.15);
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
  height: 42px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navigation-wrap {
  position: fixed !important;
  width: 102%;
  top: 0;
  left: -5px;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar {
  padding: 0;
}
.navbar-brand img {
  height: 42px;
  width: auto;
  display: block;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.nav-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
  font-size: 15px;
}
.navbar-nav span,

a {
  /* font-size: 14px; */
  font-weight: 500;
}
/* .nav-item:hover .nav-link {
  color: #004aad !important;
} */
.nav-item.active .nav-link {
  color: #000 !important;
}
/* .nav-item span a:hover {
  color: #004aad;
} */
/* .nav-item:after{position: absolute;	bottom: -5px;left: 0;width: 100%;height: 2px;content: '';background-color: #004aad;opacity: 0;   transition: all 200ms linear;} */
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}
.nav-item.active:hover:after {
  opacity: 0;
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}
.section {
  position: relative;
  width: 100%;
  display: block;
}
.full-height {
  height: 45vh;
}
.over-hide {
  overflow: hidden;
}
.absolute-center {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 20;
}
body.hero-anime p {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1700ms;
}
h1 span {
  display: inline-block;
  transition: all 300ms linear;
  opacity: 1;
  transform: translate(0);
}
body.hero-anime h1 span:nth-child(1) {
  opacity: 0;
  transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(3) {
  opacity: 0;
  transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(4) {
  opacity: 0;
  transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(5) {
  opacity: 0;
  transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(6) {
  opacity: 0;
  transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(7) {
  opacity: 0;
  transform: translateY(-40px);
}
body.hero-anime h1 span:nth-child(8) {
  opacity: 0;
  transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(9) {
  opacity: 0;
  transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(10) {
  opacity: 0;
  transform: translateY(-20px);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-size: 1rem;
}
h1 span:nth-child(1) {
  transition-delay: 1000ms;
}
h1 span:nth-child(2) {
  transition-delay: 700ms;
}
h1 span:nth-child(3) {
  transition-delay: 900ms;
}
h1 span:nth-child(4) {
  transition-delay: 800ms;
}
h1 span:nth-child(5) {
  transition-delay: 1000ms;
}
h1 span:nth-child(6) {
  transition-delay: 700ms;
}
h1 span:nth-child(7) {
  transition-delay: 900ms;
}
h1 span:nth-child(8) {
  transition-delay: 800ms;
}
h1 span:nth-child(9) {
  transition-delay: 600ms;
}
h1 span:nth-child(10) {
  transition-delay: 700ms;
}

body.hero-anime h1 span:nth-child(11) {
  opacity: 0;
  transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(12) {
  opacity: 0;
  transform: translateY(50px);
}
body.hero-anime h1 span:nth-child(13) {
  opacity: 0;
  transform: translateY(20px);
}
body.hero-anime h1 span:nth-child(14) {
  opacity: 0;
  transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(15) {
  opacity: 0;
  transform: translateY(50px);
}
h1 span:nth-child(11) {
  transition-delay: 1300ms;
}
h1 span:nth-child(12) {
  transition-delay: 1500ms;
}
h1 span:nth-child(13) {
  transition-delay: 1400ms;
}
h1 span:nth-child(14) {
  transition-delay: 1200ms;
}
h1 span:nth-child(15) {
  transition-delay: 1450ms;
}
#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
#switch {
  width: 60px;
  height: 8px;
  border: 2px solid #004aad;
  border-radius: 27px;
  background: #000;
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}
body.hero-anime #switch {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1900ms;
}
#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000;
}
.switched {
  border-color: #000 !important;
  background: #004aad !important;
}
.switched #circle {
  left: 43px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}
section.navup {
  position: relative;
  z-index: 999;
}
.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
  z-index: 9999;
}
.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
  z-index: 9999;
}
.dropdown-menu {
  right: 0 !important;
  left: auto;
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
  z-index: -9999;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  padding: 3px 0px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #004aad;
}
.header_p {
  position: relative;
  width: 100%;
  display: block;
  height: 40px;
}


.brand-logo-light {
  max-width: 250px;
}
.searchbar {
  margin-left: -45pc;
  display: flex;
  margin-top: 0px;
  border-radius: 10px;
  background-color: #fff;
  padding: 4px;
  z-index: 99;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.search_input {
  color: #000;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
  font-size: 16px;
}
.searchbar .search_input {
  width: 220px;
  caret-color: #000;
  transition: width 0.4s linear;
  height: 27px;
}
.searchbar:hover > .search_icon {
  background: white;
  color: #004aad;
}
.search_icon {
  height: 30px;
  width: 30px;
  margin: 2px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #004aad;
  text-decoration: none;
}
.search_icon:hover {
  text-decoration: none;
}
.cart {
  width: 100px;
  height: 50px;
  border-radius: 5px;
  margin: 0;
  padding: 15px 10px 0 0;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: #000;
}
.cart img {
  display: inline-block;
  max-width: 24px;
}

.modal.show .modal-dialog {
  transform: none;
}

.cart span {
  display: inline-block;
}
a.cart {
  text-decoration: none;
  color: #000;
}
.submenu {
  display: block;
  margin: 0 auto !important;
  text-align: center;
  background: #fff;
}
.submenu li {
  display: inline-block;
  margin: 0 10px;
}
.submenu li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.submenu li a:hover {
  text-decoration: none;
  color: #004aad;
  transition: 0.5s all;
}
.submenu li i {
  margin: 0 4px;
}

.tab {
  overflow: hidden;
  width: 720px;
  display: table;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0px;
}
.tab button {
  cursor: pointer;
  width: auto;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 50px;
  box-shadow: 1px 1px 15px 0px #00000017;
  border: none;
  outline: none;
  margin: 10px 10px;
  background: #fff;
  font-weight: 700;
  font-size: 14px;
}
.tab button img {
  margin-left: -10px;
  margin-right: 10px;
  padding-left: 10px;
}
.tab button:hover {
  cursor: pointer;
  border: 1 px #004aad solid;
}
.tab button.active {
  background: #004aad;
  color: #fff;
  box-shadow: 1px 1px 15px 1px #004aad33;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.5s ease;
}
.tabcontent {
  display: none;
  border-top: none;
}
.grid .column {
  width: 24.5%;
  margin-right: 9px;
  float: left;
  vertical-align: top;
}
.grid .column:last-child {
  margin-right: 0px;
}
.grid-item a img {
  max-width: 100%;
  display: block;
  min-width: 100%;
  object-fit: cover;
}
img {
  object-fit: fill;
  margin-left: -5px;
}
.grid .grid-item {
  float: left;
  vertical-align: top;
  z-index: 0;
  margin-bottom: 20px;
  min-width: 100%;
}
.grid-item a {
  display: block;
  position: relative;
}
.grid-item .wrap {
  position: relative;
}
.grid-item .wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  border-radius: 20px;

  background: -moz-linear-gradient(
    top,
    rgba(125, 185, 232, 0) 0%,
    rgba(78, 115, 144, 0) 38%,
    rgba(73, 108, 135, 0) 42%,
    rgba(0, 0, 0, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(125, 185, 232, 0) 0%,
    rgba(78, 115, 144, 0) 38%,
    rgba(73, 108, 135, 0) 42%,
    rgba(0, 0, 0, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(125, 185, 232, 0) 0%,
    rgba(78, 115, 144, 0) 38%,
    rgba(73, 108, 135, 0) 42%,
    rgba(0, 0, 0, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.grid-item span.auth {
  position: absolute;
  bottom: 26px;
  display: block;
  padding: 0 3%;
  width: 95%;
  z-index: 9;
}
.grid-item span.auth h5 {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 4px;
}
.grid-item span.auth small {
  font-size: 15px;
  color: #fff;
  font-weight: 300;
}
.btm_btn {
  position: absolute;
  bottom: -20px;
  z-index: 99;
  color: #fff;
}
.btm_btn a {
  display: inline-block;
  margin: 0px 4px;
  color: #fff;
  padding: 0px;
  cursor: pointer;
  font-size: 14px;
}
.btm_btn a:hover {
  color: #004aad;
  text-decoration: none;
}

.accordion {
  width: 100%;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  list-style: none;
  float: right;
  z-index: 99;
  position: relative;
}
.accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 1px 1px 15px 0px #00000017;
  border-radius: 10px;
}
.accordion .link img {
  max-width: 30px;
  margin-right: 10px;
}
.accordion li:last-child .link {
  border-bottom: 0;
}
.accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}
.accordion li.open .link {
  color: #004aad;
}
.accordion li.open i {
  color: #004aad;
}
.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.submedia {
  /*display: none;*/
  background: #444359;
  font-size: 14px;
  width: 200px;
  margin: 0 auto;
  border-radius: 10px;
  z-index: 9;
  position: absolute;
}
.submedia li {
  border-bottom: 1px solid #4b4a5e;
  list-style: none;
}
.submedia li .form-check {
  padding-left: 30px;
}
.submedia li:last-child {
  border-bottom: none;
  list-style: none;
}
.submedia a {
  display: block;
  text-decoration: none;
  color: #d9d9d9;
  padding: 7px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.submedia a:hover {
  background: #004aad;
  color: #fff;
}

.pagcent {
  margin: 0 auto;
  text-align: center;
  display: table;
}
.pagcent .page-link {
  color: #004aad;
}
/* .negindex{z-index: -99;} */
.btntag {
  color: #004aad;
  border: 1px #004aad solid;
  font-size: 16px;
  border-radius: 2px;
  padding: 4px 7px;
  float: left;
  margin: 0 15px 5px;
}
.btnsocial {
  border: none !important;
}
.btntag:hover {
  background-color: #004aad;
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease-in;
}
.btnsocial:hover {
  background-color: #fff !important;
}
.btnsocial {
  background-color: #fff !important;
}
.accordiontwo {
  width: 100%;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  list-style: none;
  float: left;
  z-index: 99;
  position: relative;
}
.accordiontwo .linktwo {
  cursor: pointer;
  display: block;
  padding: 15px;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 1px 1px 15px 0px #00000017;
  border-radius: 10px;
}
.accordiontwo .linktwo img {
  max-width: 30px;
  margin-right: 10px;
}
.accordiontwo li:last-child .linktwo {
  border-bottom: 0;
}
.accordiontwo li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.accordiontwo li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}
.accordiontwo li.open .link {
  color: #004aad;
}
.accordiontwo li.open i {
  color: #004aad;
}
.accordiontwo li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.submediatwo {
  /*display: none;*/
  background: #444359;
  font-size: 14px;
  width: 200px;
  margin: 0 auto;
  border-radius: 10px;
  z-index: 9999;
  position: absolute;
}
.submediatwo li {
  border-bottom: 1px solid #4b4a5e;
  list-style: none;
}
.submediatwo li .form-check {
  padding-left: 30px;
}
.submediatwo li:last-child {
  border-bottom: none;
  list-style: none;
}
.submediatwo a {
  display: block;
  text-decoration: none;
  color: #d9d9d9;
  padding: 7px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.submediatwo a:hover {
  background: #004aad;
  color: #fff;
}

.item-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f6f6f6;
}
.menu-items {
  list-style-type: none;
  font-size: 11px;
  display: inline-flex;
  margin-bottom: 0;
  margin-top: 20px;
}

.psection {
  width: 100%;
}
.psection .box {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
.title-price {
  margin-top: 10px;
  margin-bottom: 0;
}
.title-attr {
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}
.title-attr div {
  display: inline-block;
}
.btn-minus {
  cursor: pointer;
  font-size: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid gray;
  border-radius: 2px;
  border-right: 0;
}
.btn-plus {
  cursor: pointer;
  font-size: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid gray;
  border-radius: 2px;
  border-left: 0;
}
div.psection > div {
  width: 100%;
  display: inline-flex;
}
div.psection > div > input {
  font-size: 14px;
  padding: 4px;
  max-width: 50px;
  text-align: center;
}
.attr,
.attr2 {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
  border: 1px #ccc solid;
}
.attr.active,
.attr2.active {
  border: 2px solid #004aad;
}
.artdetail h2 {
  font-weight: 500;
  font-size: 22px;
  color: #000;
}
.artdetail h3 {
  font-weight: 700;
  font-size: 24px;
  color: #000;
}
.artdetail {
  min-height: 520px;
}
.detailbtn {
  color: #004aad;
  border: 1px #004aad solid;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 20px;
}
.detailbtn:hover {
  background-color: #004aad;
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease-in;
}
.btn-follow {
  border: 1px #000 solid;
  color: #000;
  font-size: 14px;
}
.btn-follow:hover {
  border: 1px #fff solid;
  color: #fff;
  font-size: 14px;
  background: #004aad;
  transition: 0.3s ease-in;
}
.btn-fc {
  background: #3b5997;
  color: #fff;
}
.btn-p {
  background: #004aad;
  color: #fff;
}
.btn-ti {
  background: #4ba6ed;
  color: #fff;
}
.btn-l {
  background: #0070ab;
  color: #fff;
}
.stars i {
  color: #ffc107;
  font-size: 16px;
}
.comment {
  overflow: hidden;
  padding: 0 0 2em;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.03);
  padding: 20px 40px;
  border-radius: 10px;
  margin: 0 0 1em;
  /* *zoom: 1; */
}

.comment-img {
  float: left;
  margin-right: 33px;
  border-radius: 5px;
  overflow: hidden;
}
.comment-img img {
  display: block;
}
.comment-body {
  overflow: hidden;
}
.comment .text {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #fff;
}
.comment .text p:last-child {
  margin: 0;
}
.comment .attribution {
  margin: 0.5em 0 0;
  font-size: 14px;
  color: #000;
}
.comment .attribution a {
  color: #004aad;
}
/* Decoration */
.comments,
.comment {
  position: relative;
}
.comments:before,
.comment:before,
.comment .text:before {
  content: "";
  position: absolute;
  top: 37px;
  left: 105px;
}
.comments:before {
  width: 3px;
  top: -20px;
  bottom: -20px;
  background: rgba(0, 0, 0, 0.1);
}
.comment:before {
  width: 9px;
  height: 9px;
  border: 3px solid #fff;
  border-radius: 100px;
  margin: 16px 0 0 -6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #000;
}
.comment:hover:before {
  background: #004aad;
}
.comment .text:before {
  top: 54px;
  left: 118px;
  width: 9px;
  height: 9px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #000;
  background: #fff;
  /* -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg); */
}
.modal .writerev {
  max-width: 60% !important;
}
.clrbor {
  box-shadow: 0px 0px 10px 5px rgba(114, 206, 229, 0.15);
  border-radius: 30px;
  margin-bottom: 30px;
}
.revfont i {
  color: #004aad;
  font-size: 20px;
}
.btn-color-fill {
  color: #004aad;
  border: 1px #004aad solid;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 20px;
}
.btn-color-fill:hover {
  background-color: #004aad;
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease-in;
}

.img-container {
  position: relative;
}
.img-container .first {
  position: absolute;
  width: 100%;
}
.img-container img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 99;
}
.product-detail-container {
  padding: 10px;
}
.ratings i {
  color: #ffc107;
}
.ratings span {
  color: #ffc107;
}

.title-gallery h5 {
  font-weight: 700;
  font-size: 24px;
  color: #004aad;
}
.btnlikedet {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  background-color: #004aad;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
}
.btnlikedet:hover {
  text-decoration: none;
  transition: 0.5s all;
  color: #000;
}

.zoom {
  height: 400px;
  position: relative;
}
.zoom-main {
  height: 80%;
  position: relative;
  text-align: center;
}
.zoom-thumb-hide .zoom-main {
  height: 100%;
}
.zoom-main span {
  background-position: 50% 50%;
  background-repeat: none;
  background-size: 200%;
  overflow: hidden;
  position: absolute;
  cursor: zoom-in;
  border: dashed thin #ccc;
  position: relative;
  display: inline-block;
  height: 100%;
}
.zoom-right .zoom-main span {
  left: 0;
}
.zoom-main img {
  height: 100%;
  display: block;
  background: #fff;
  transition: opacity 0.5s;
}
.zoom-main img:hover {
  opacity: 0;
}
.zoom-right .zoom-main {
  height: 100%;
  float: left;
  padding-right: 5px;
}
.zoom-thumb {
  height: 20%;
  text-align: center;
  padding: 5px;
}
.zoom-right .zoom-thumb {
  display: inline;
}
.zoom .zoom-thumb a {
  margin: 0 5px 5px 0;
  padding: 5px;
  border: solid thin #ccc;
  height: 100%;
  display: inline-block;
  cursor: pointer;
}
.zoom-right .zoom-thumb a {
  float: left;
  height: 20%;
}
.zoom .zoom-thumb a img {
  height: 100%;
}
.zoom-left .zoom-main,
.zoom-left .zoom-thumb a {
  float: right;
  width: auto;
}
.wrap-drop {
  background: #f9f9f9;
  float: right;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 0 20px 0 20px;
  float: left;
  max-width: 225px;
  padding: 1rem;
  position: relative;
  width: 200px;
  z-index: 3;
  border-radius: 6px;
  text-align: left;
  float: right;
}
.wrap-drop::after {
  border-color: #000 transparent;
  border-style: solid;
  border-width: 10px 10px 0;
  content: "";
  height: 0;
  margin-top: -4px;
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 0;
}
.wrap-drop .drop {
  background: #f9f9f9;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  display: none;
  left: 0;
  list-style: none;
  margin-top: 0;
  opacity: 0;
  padding-left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
}
.wrap-drop .drop li a {
  color: #000;
  display: block;
  padding: 1rem;
  text-decoration: none;
  text-align: left;
}
.wrap-drop span {
  color: #000;
}
.wrap-drop .drop li:hover a {
  background-color: #004aad;
  color: #e7ded5;
}
.wrap-drop.active::after {
  border-width: 0 10px 10px;
}
.wrap-drop.active .drop {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.product {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: initial;
}
/* .product__inner {
  /*display: flex;*/
/* } */ */
.product__slider {
  margin: auto;
}
.product__slider-item {
  width: unset !important;
}
.product__slider-box {
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  max-width: 800px;
}
.product__slider-box img {
  max-width: 750px;
  border-radius: 20px;
}
.nav__slider {
  width: 85px;
  margin: auto !important;
  margin-top: 20px;
}
.nav__slider-box {
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px #ccc solid;
}
.nav__slider img {
  width: 75px;
  border-radius: 20px;
}
.nav__slider-item.slick-slide.slick-current.slick-active.slick-center {
  border: 1px #004aad solid;
  border-radius: 10px;
}
.product__slider .slick-dots li.slick-active button:before {
  color: #fff;
}
.product__slider .slick-dots li button:before {
  color: #fff;
}
.product__slider .slick-current .slick-active {
  width: unset !important;
}
.product__slider .slick-vertical .slick-slide {
  border: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-main .nav__slider-item {
  width: 100% !important;
  height: 75px;
  margin-bottom: 5px;
  padding: 4px; /*border: 1px #ccc solid;*/
}
/*.product-main .nav__slider-item img{ /*border-radius: 5px !important;}*/
.product-main .nav__slider .slick-list .slick-track {
  width: auto !important;
}
.bglogin {
  background: url("http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.card-grid {
  height: auto;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.social_icon span {
  font-size: 24px;
  color: #004aad;
  margin: 10px auto;
}
.social_icon span:hover {
  color: white;
  cursor: pointer;
}
.social_icon {
  position: relative;
}
.input-group-prepend span {
  width: 50px;
  background-color: #004aad;
  color: black;
  border: 0 !important;
}
.remember input {
  width: 16px;
  height: 16px;
  margin-left: 15px;
  margin-right: 5px;
}
.login_btn {
  color: #000;
  background-color: #004aad;
  width: 100px;
}
.login_btn:hover {
  color: 000;
  background-color: white;
}
.links {
  color: white;
}
.links a {
  margin-left: 4px;
}

button.signinn {
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid #004aad;
  background-color: #004aad;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  transition: transform 80ms ease-in;
}
button.signinn:active {
  transform: scale(0.95);
}
button.signinn:focus {
  outline: none;
}
button.ghost {
  background-color: transparent;
  border: 1px #ffffff solid;
  border-radius: 100px;
  color: #ffffff;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  padding: 7px 25px;
  letter-spacing: 1px;
  transition: transform 80ms ease-in;
}
form.account {
  background-color: #ffffff;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
}
@media (max-width: 768px) {
  form.account {
    background-color: #ffffff;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    padding: 0px !important;
    height: 100%;
  }
}
.account input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
.contain {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  /* min-height: 600px; */
  margin: 50px auto;
}
.form-contain {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}
.account button {
  margin-top: -16px;
  border-radius: 100px;
  padding: 7px 25px;
  font-size: 14px;
}
.sign-in-contain {
  left: 0;
  width: 50%;
  z-index: 2;
}
.contain.right-panel-active .sign-in-contain {
  transform: translateX(100%);
}
.sign-up-contain {
  left: 0;
  width: 50%;
  /* opacity: 0; */
  z-index: 1;
}
.contain.right-panel-active .sign-up-contain {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-contain {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}
.contain.right-panel-active .overlay-contain {
  transform: translateX(-100%);
}
.overlay {
  background: #004aad;
  background: -webkit-linear-gradient(to right, #004aad, #004aad);
  background: linear-gradient(to right, #004aad, #004aad);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.contain.right-panel-active .overlay {
  transform: translateX(50%);
}
.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.overlay-left {
  transform: translateX(-20%);
}
.contain.right-panel-active .overlay-left {
  transform: translateX(0);
}
.overlay-right {
  right: 0;
  transform: translateX(0);
}
.contain.right-panel-active .overlay-right {
  transform: translateX(20%);
}
.social-contain {
  margin: 20px 0;
}
.social-contain a {
  border: 1px solid #dddddd;
  color: #000;
  font-weight: bold;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.social-contain a:hover {
  text-decoration: none;
  background: #004aad;
  color: #fff;
  border: none;
  transition: all 200ms linear;
}

#site-header {
  background: #fff;
}
#site-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  width: 100%;
  display: inline-block;
}
#site-header {
  margin: 0 0 30px 0;
}
#site-header h1 {
  font-size: 31px;
  font-weight: 300;
  padding: 40px 0;
  position: relative;
  margin: 0;
}
#site-header h1 span {
  color: #004aad;
}
#site-header h1 span.last-span {
  background: #fff;
  padding-right: 150px;
  position: absolute;
  left: 217px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
#site-header h1:hover span.last-span,
#site-header h1 span.is-open {
  left: 363px;
}
#site-header h1 em {
  font-size: 16px;
  font-style: normal;
  vertical-align: middle;
}
#shopcart {
  width: 100%;
}
#shopcart h1 {
  font-weight: 300;
}
#shopcart a {
  color: #004aad;
  text-decoration: none;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
#shopcart a:hover {
  color: #000;
}
.cartproduct.removed {
  margin-left: 980px !important;
  opacity: 0;
}
.cartproduct {
  margin: 20px 0;
  width: 100%;
  height: 195px;
  position: relative;
  -webkit-transition: margin 0.2s linear, opacity 0.2s linear;
  -moz-transition: margin 0.2s linear, opacity 0.2s linear;
  -ms-transition: margin 0.2s linear, opacity 0.2s linear;
  -o-transition: margin 0.2s linear, opacity 0.2s linear;
  transition: margin 0.2s linear, opacity 0.2s linear;
}
.cartproduct img {
  width: 100%;
  height: 100%;
}
.cartproduct header,
.cartproduct .content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-style: none none solid none;
  float: left;
}
.cartproduct header {
  background: #000;
  margin: 0 1% 10px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 24%;
  height: 195px;
}
.cartproduct header:hover img {
  opacity: 0.7;
}
.cartproduct header:hover h3 {
  bottom: 0px;
}
.cartproduct header h3 {
  background: #004aad;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 49px;
  margin: 0;
  padding: 0 30px;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  -webkit-transition: bottom 0.1s linear;
  -moz-transition: bottom 0.1s linear;
  -ms-transition: bottom 0.1s linear;
  -o-transition: bottom 0.1s linear;
  transition: bottom 0.1s linear;
}
.remove {
  cursor: pointer;
}
.cartproduct .content {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: auto;
  padding: 0 20px;
  width: 75%;
}
.content h1 {
  color: #004aad;
  font-size: 25px;
  font-weight: 300;
  margin: 17px 0 20px 0;
}
.content h2.content {
  height: 50px;
  margin: 6px 0 0 0;
  padding: 0;
}
.content h2.price {
  background: #f9f9f9;
  color: #000;
  float: right;
  font-size: 16px;
  font-weight: 700;
  line-height: 49px;
  margin: 0;
  padding: 0 30px;
}
.content h2.full-price {
  background: #004aad;
  color: #fff;
  float: right;
  font-size: 22px;
  font-weight: 300;
  line-height: 49px;
  margin: 0;
  padding: 0 30px;
  -webkit-transition: margin 0.15s linear;
  -moz-transition: margin 0.15s linear;
  -ms-transition: margin 0.15s linear;
  -o-transition: margin 0.15s linear;
  transition: margin 0.15s linear;
}
.qt,
.qt-plus,
.qt-minus {
  display: block;
  float: left;
}
.qt {
  font-size: 20px;
  line-height: 50px;
  width: 70px;
  text-align: center;
  font-weight: 700;
}
.qt-plus,
.qt-minus {
  background: #f9f9f9;
  border: none;
  font-size: 30px;
  font-weight: 300;
  height: 50px;
  padding: 0 20px;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}
.qt-plus:hover,
.qt-minus:hover {
  background: #004aad;
  color: #fff;
  cursor: pointer;
}
.qt-plus {
  line-height: 50px;
}
.qt-minus {
  line-height: 47px;
}
#site-footer h1 {
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-style: none none solid none;
  font-size: 24px;
  font-weight: 300;
  margin: 0 0 7px 0;
  padding: 14px 40px;
  text-align: center;
}
#site-footer h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 10px 0 0 0;
}
#site-footer h3 {
  font-size: 19px;
  font-weight: 300;
  margin: 15px 0;
}
#site-footer .left {
  float: left;
}
#site-footer .right {
  float: right;
}
.type {
  background: #f9f9f9;
  font-size: 13px;
  padding: 10px 16px;
  left: 100%;
}
.type,
.color {
  position: absolute;
}
.color {
  width: 40px;
  height: 40px;
  right: -40px;
}
.red {
  background: #004aad;
}
.yellow {
  background: #f1c40f;
}
.blue {
  background: #3598dc;
}
.minused {
  margin: 0 50px 0 0 !important;
}
.added {
  margin: 0 -50px 0 0 !important;
}

.shpg {
  margin: 0 auto;
}
.shpg h2 {
  font-size: 28px;
  color: #004aad;
  text-align: left;
  border-left: 5px #162c4f solid;
  padding: 15px;
  font-weight: 300;
  margin: 0px 0 20px;
}
#shopping {
  background: #059acf;
  padding: 60px 60px;
  border-radius: 10px;
}
#shopping img {
  max-height: 100px;
  max-width: 150px;
  text-align: center;
  margin: 0 auto;
}
#shopping .slick-next {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-size: 24px 24px;
  display: block;
  right: 30px;
}
#shopping .slick-prev {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-size: 24px 24px;
  display: block;
  left: 30px;
}
#shopping .slick-track {
  display: inline-flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
#shopping .slick-prev:before,
#shopping .slick-next:before {
  color: #fff;
  font-size: 25px;
}
#shopping .slick-dots {
  width: 90%;
  bottom: 0;
}
#shopping .slick-dots li.slick-active button:before {
  color: #fff;
}
.travl {
  margin: 0 auto;
}
.travl h2 {
  font-size: 28px;
  color: #004aad;
  text-align: left;
  border-left: 5px #162c4f solid;
  padding: 15px;
  font-weight: 300;
  margin: 0px 0 20px;
}
#travel {
  background: #f77d48;
  padding: 60px 60px;
  border-radius: 10px;
}
#travel img {
  max-height: 100px;
  max-width: 150px;
  text-align: center;
  margin: 0 auto;
}
#travel .slick-next {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-size: 24px 24px;
  display: block;
  right: 30px;
}
#travel .slick-prev {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-size: 24px 24px;
  display: block;
  left: 30px;
}
#travel .slick-track {
  display: inline-flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
#travel .slick-prev:before,
#shopping .slick-next:before {
  color: #fff;
  font-size: 25px;
}
#travel .slick-dots {
  width: 90%;
  bottom: 0;
}
#travel .slick-dots li.slick-active button:before {
  color: #fff;
}

#slidervertical input[type="radio"] {
  display: none;
}
#slidervertical {
  height: 650px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
#slidervertical label {
  margin: auto;
  width: 40%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
}

#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(-30%, 0, -200px);
}

#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, -100px);
}

#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  box-shadow: 0 0px 25px 0 rgba(0, 0, 0, 0.1), 0 0px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0);
}

#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow: 0 0px 25px 0 rgba(0, 0, 0, 0.1), 0 0px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(15%, 0, -100px);
}

#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(30%, 0, -200px);
}

#slide1 {
  background: #fff;
}
#slide2 {
  background: #fff;
}
#slide3 {
  background: #fff;
}
#slide4 {
  background: #fff;
}
#slide5 {
  background: #fff;
}

.rotateturn-scroll {
  overflow: scroll;
}
.rotateturn {
  float: left;
  padding: 15px;
}
.rotateturn .column {
  width: 100% !important;
}
.rotateturn .column .grid-item {
  float: left !important;
  min-width: 46% !important;
  margin: 0 5px;
}
.rotateturn .head {
  padding: 2px 0px;
  width: 100%;
  float: left;
  font-weight: 600;
  font-size: 14px;
}
.rotateturn p.public-text-justify {
  font-size: 12px !important;
}
ul.about {
  margin: auto;
}
ul.about li {
  font-weight: 300;
  line-height: 20px;
  list-style: none;
  padding: 0px;
}
ul.about li i {
  font-size: 18px;
  color: #004aad;
  margin-right: 10px;
}
.art_head {
  font-size: 16px;

  color: #bbb4b5;
  font-weight: 600;
  padding-left: 20px;
  display: inline;
  width: 100%;
  float: left;
  margin-bottom: 5px;
  border-left: 4px #004aad solid;
  padding: 5px;
}
.card_fol {
  width: 49%;
  margin: 0 1% 10px 0;
  float: left;
  padding: 10px;
}
.card_fol_two {
  width: 99%;
  margin: 0 1% 10px 0;
  float: left;
  padding: 10px;
}
.card_fol_two .point {
  color: #004aad;
}
.rnkpoint {
  position: absolute;
  right: 20px;
}
.rnkpoint .point_cont {
  float: left;
  text-align: center;
  margin: 0 auto;
  width: 70px;
}
.rnkpoint .point_cont .rank {
  color: #004aad;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}
.rnkpoint img {
  max-width: 28px;
}

.m_container {
  background: #fff;
  width: 100%;
  padding: 20px 50px;
  display: block;
  float: left;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.03);
}
.m_container h3 {
  font-size: 16px;
  font-weight: 500;
}
.icon_ipload {
  margin: 0 10px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  display: inline-table;
  width: 20%;
  height: 100px;
  border: 1px #eee solid;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.438);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
}
.attmed {
  background-color: #fff;
  padding: 10px 15px;
  border: 1px #f9f9f9 solid;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}
.multiSelect::-webkit-file-upload-button {
  background: #004aad;
  color: #fff;
  width: 250px;
  padding: 7px 20px;
  border: none;
  outline: none;
  border-radius: 4px;
}
#projectThumbnailFile::-webkit-file-upload-button {
  background: #004aad;
  color: #fff;
  width: 250px;
  padding: 7px 20px;
  border: none;
  outline: none;
  border-radius: 4px;
}
.icon_ipload img {
  max-width: 50px;
  height: 50px !important;
}
.icon_ipload h5 {
  color: #004aad;
  font-weight: 500;
  font-size: 17px;
}
.icon_ipload span {
  color: #ccc;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
}
.imageuploadify {
  position: relative;
  background-color: #f7f7f9;
  color: #5a55a3;
  display: inline;
  border: none !important;
}
.imageuploadify .fa-cloud-upload {
  display: none !important;
}
.well {
  padding: 0 !important;
  box-shadow: none;
}
.choose_logo label {
  width: 100%;
  margin: 10px 0 -15px 0;
  display: block;
}
.choose_logo .btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #004aad;
  cursor: inherit;
  display: block;
}
.custom_choose {
  background-color: #004aad !important;
  color: #fff;
}
#inputfile {
  margin: 0px;
  padding: 0px;
}
.other_det select {
  width: 100%;
  margin-bottom: 15px;
}

.imageuploadify .imageuploadify-overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  display: none;
  font-size: 7em;
  background-color: rgba(242, 242, 242, 0.7);
  text-align: center;
  pointer-events: none;
}

.imageuploadify .imageuploadify-overlay i {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.imageuploadify .imageuploadify-images-list {
  display: inline-block;
}
.imageuploadify .imageuploadify-images-list i {
  display: block;
  font-size: 7em;
  text-align: center;
  margin-top: 0.5em;
  padding-bottom: 12px;
}
.imageuploadify .imageuploadify-images-list span.imageuploadify-message {
  display: none !important;
}
.imageuploadify .imageuploadify-images-list button.btn-default {
  display: block;
  color: #fff !important;
  background: #004aad !important;
  border-radius: 4px;
  margin: 5px auto;
  width: 275px;
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-right: 1em !important;
  margin-bottom: 1em !important;
  float: left;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #888;
}
.imageuploadify
  .imageuploadify-images-list
  .imageuploadify-container
  button.btn-danger {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  font-size: 10px;
  line-height: 1.42;
  padding: 2px 0;
  text-align: center;
  z-index: 3;
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container img {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}
.imageuploadify
  .imageuploadify-images-list
  .imageuploadify-container
  .imageuploadify-details {
  position: absolute;
  top: 0;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  opacity: 0;
  margin-right: 20px;
}

#editor {
  resize: vertical;
  overflow: auto;
  border: 1px solid silver;
  border-radius: 5px;
  min-height: 100px;
  box-shadow: inset 0 0 10px silver;
  padding: 2.5em;
  background: white;
  margin: 0 auto;
  width: 100%;
}

.ss-main {
  position: relative;
  display: inline-block;
  user-select: none;
  color: #666;
  width: 100%;
}
.ss-main .ss-single-selected {
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 30px;
  padding: 6px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #fff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color 0.3s;
}
.ss-main .ss-single-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}
.ss-main .ss-single-selected.ss-open-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ss-main .ss-single-selected.ss-open-below {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ss-main .ss-single-selected .placeholder {
  flex: 1 1 100%;
  text-align: left;
  width: calc(100% - 30px);
  line-height: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ss-main .ss-single-selected .placeholder,
.ss-main .ss-single-selected .placeholder * {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ss-main .ss-single-selected .placeholder * {
  width: auto;
}
.ss-main .ss-single-selected .placeholder .ss-disabled {
  cursor: pointer;
  color: #8a8a8a;
}
.ss-main .ss-single-selected .ss-deselect {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px;
  font-weight: 700;
}
.ss-main .ss-single-selected .ss-deselect.ss-hide {
  display: none;
}
.ss-main .ss-single-selected .ss-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px;
}
.ss-main .ss-single-selected .ss-arrow span {
  border: solid #666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.2s, margin 0.2s;
}
.ss-main .ss-single-selected .ss-arrow span.arrow-up {
  transform: rotate(-135deg);
  margin: 3px 0 0;
}
.ss-main .ss-single-selected .ss-arrow span.arrow-down {
  transform: rotate(45deg);
  margin: -3px 0 0;
}
.ss-main .ss-multi-selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 30px;
  width: 100%;
  padding: 0 0 0 3px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #fff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color 0.3s;
}
.ss-main .ss-multi-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}
.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled {
  color: #666;
}
.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed;
}
.ss-main .ss-multi-selected.ss-open-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ss-main .ss-multi-selected.ss-open-below {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ss-main .ss-multi-selected .ss-values {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 1 100%;
  width: calc(100% - 30px);
}
.ss-main .ss-multi-selected .ss-values .ss-disabled {
  display: flex;
  padding: 10px 5px;
  margin: 2px 0;
  line-height: 1em;
  align-items: center;
  width: 100%;
  color: #8a8a8a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
.ss-main .ss-multi-selected .ss-values .ss-value {
  display: flex;
  user-select: none;
  align-items: center;
  font-size: 12px;
  padding: 3px 10px;
  margin: 3px 5px 3px 0;
  color: #fff;
  background-color: #004aad !important;
  border-radius: 4px;
  animation-name: scaleIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}
.ss-main .ss-multi-selected .ss-values .ss-value.ss-out {
  animation-name: scaleOut;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}
.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  margin: 0 0 0 5px;
  cursor: pointer;
}
.ss-main .ss-multi-selected .ss-add {
  display: flex;
  flex: 0 1 3px;
  margin: 9px 12px 0 5px;
  padding: 5px;
}
.ss-main .ss-multi-selected .ss-add .ss-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666;
  position: relative;
  height: 10px;
  width: 2px;
  transition: transform 0.2s;
}
.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  background: #666;
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -4px;
  top: 4px;
}
.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  transform: rotate(45deg);
}
.ss-main .ss-content {
  position: absolute;
  width: 100%;
  margin: -1px 0 0;
  box-sizing: border-box;
  border: 1px solid #dcdee2;
  z-index: 1010;
  background-color: #fff;
  transform-origin: center top;
  transition: transform 0.2s, opacity 0.2s;
  opacity: 0;
  transform: scaleY(0);
}
.ss-main .ss-content.ss-open {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}
.ss-main .ss-content .ss-search {
  display: flex;
  flex-direction: row;
  padding: 8px 8px 6px;
}
.ss-main .ss-content .ss-search.ss-hide,
.ss-main .ss-content .ss-search.ss-hide input {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}
.ss-main .ss-content .ss-search input {
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  height: 30px;
  padding: 6px 8px;
  margin: 0;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #fff;
  outline: 0;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* -webkit-appearance: textfield; */
}
.ss-main .ss-content .ss-search input::placeholder {
  color: #bdbdbd;
  vertical-align: middle;
}
.ss-main .ss-content .ss-search input:focus {
  box-shadow: 0 0 5px #004aad;
}
.ss-main .ss-content .ss-search .ss-addable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  flex: 0 0 30px;
  height: 30px;
  margin: 0 0 0 8px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  box-sizing: border-box;
}
.ss-main .ss-content .ss-addable {
  padding-top: 0;
}
.ss-main .ss-content .ss-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}
.ss-main .ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  padding: 6px 10px;
  font-weight: 700;
}
.ss-main .ss-content .ss-list .ss-optgroup .ss-option {
  padding: 6px 6px 6px 25px;
}
.ss-main .ss-content .ss-list .ss-optgroup-label-selectable {
  cursor: pointer;
}
.ss-main .ss-content .ss-list .ss-optgroup-label-selectable:hover {
  color: #fff;
  background-color: #004aad;
}
.ss-main .ss-content .ss-list .ss-option {
  padding: 6px 10px;
  cursor: pointer;
  user-select: none;
}
.ss-main .ss-content .ss-list .ss-option * {
  display: inline-block;
}
.ss-main .ss-content .ss-list .ss-option.ss-highlighted,
.ss-main .ss-content .ss-list .ss-option:hover {
  color: #fff;
  background-color: #004aad;
}
.ss-main .ss-content .ss-list .ss-option.ss-disabled {
  cursor: default;
  color: #bdbdbd;
  background-color: #fff;
}
.ss-main .ss-content .ss-list .ss-option.ss-hide {
  display: none;
}
.ss-main .ss-content .ss-list .ss-option .ss-search-highlight {
  background-color: rgba(255, 247, 0, 0.38);
}
.ctabtn {
  border: 1px #000 solid;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-left: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.ctabtn span {
  margin: 0 5px;
}
.ctabtn:hover {
  border: 1px #004aad solid;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #004aad;
  margin-left: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
}
.artist {
  text-align: center;
  margin: 0 auto;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
}
.artist img {
  -webkit-filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.3));
}
#mytab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  padding: 7px 15px !important;
  color: #004aad !important;
  border-color: #004aad #004aad #fff;
  z-index: 9999;
}
#mytab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  padding: 7px 15px !important;
}
.tab-content .head {
  padding: 5px 20px;
  width: 100%;
  float: left;
  font-weight: 600;
  font-size: 16px;
}

.portedit {
  position: absolute !important;
  top: 20px;
  right: 20px;
  display: inline;
  cursor: pointer;
  z-index: 99;
}
.portedit i {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}
.portedit i:hover {
  color: #004aad;
  font-size: 20px;
  font-weight: 300;
}
.profabout {
  background: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  border: 1px #eee solid;
  padding: 10px;
  width: 24%;
  height: auto;
  margin: 10px 15px;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
}

.profabout img {
  max-height: 40px;
  margin-bottom: 10px;
}
.profabout h5 {
  color: #004aad;
  font-weight: 700;
  font-size: 16px;
}
.profabout h5:hover {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}
.profabout span {
  color: #ccc;
  font-weight: 300;
  font-size: 14px;
}
.aboutsocial {
  width: 25%;
  float: left;
  margin: 0px ​20px 10px;
}
.aboutsocial:hover {
  text-decoration: none;
}
.aboutsocial i {
  font-size: 40px;
  margin-bottom: 10px;
}
.center-nav {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto 20px;
  width: auto;
  max-width: 700px;
  text-align: center;
}
.btn-facebook {
  color: #3b5998;
}
.btn-linkedin {
  color: #005f8d;
}
.btn-twitter {
  color: #309aea;
}
.btn-youtube {
  color: #ff0000;
}

.bnrcard .card-heading {
  padding: 0 20px;
  margin: 0;
}
.bnrcard .card-heading.simple {
  font-size: 20px;
  font-weight: 300;
  color: #777;
  border-bottom: 1px solid #e5e5e5;
}
.bnrcard .card-heading.image img {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  vertical-align: top;
  border: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.bnrcard .card-heading.image .card-heading-header {
  display: inline-block;
  vertical-align: top;
}
.bnrcard .card-heading.image .card-heading-header h3 {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}
.bnrcard .card-heading.image .card-heading-header span {
  font-size: 12px;
  color: #999999;
}
.bnrcard .card-body {
  padding: 0 20px;
  margin-top: 20px;
}
.bnrcard .card-media {
  padding: 0 20px;
  margin: 0 -14px;
}
.bnrcard .card-media img {
  max-width: 100%;
  max-height: 100%;
}
.bnrcard .card-actions {
  min-height: 30px;
  padding: 0 20px 20px 20px;
  margin: 20px 0 0 0;
}
.bnrcard .card-comments {
  padding: 20px;
  margin: 0;
  background-color: #f8f8f8;
}
.bnrcard .card-comments .comments-collapse-toggle {
  padding: 0;
  margin: 0 20px 12px 20px;
}
.bnrcard .card-comments .comments-collapse-toggle a,
.bnrcard .card-comments .comments-collapse-toggle span {
  padding-right: 5px;
  overflow: hidden;
  font-size: 12px;
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-comments .media-heading {
  font-size: 13px;
  font-weight: bold;
}
.bnrcard.people {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 300px;
  padding-top: 0;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}
.bnrcard.people:first-child {
  margin-left: 0;
}
.bnrcard.people .card-top {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 170px;
  height: 150px;
  background-color: #ffffff;
}
.bnrcard.people .card-top.green {
  background-color: #53a93f;
}
.bnrcard.people .card-top.blue {
  background-color: #427fed;
}
.bnrcard.people .card-info {
  position: absolute;
  top: 150px;
  display: inline-block;
  width: 100%;
  height: 101px;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bnrcard.people .card-info .title {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #404040;
}
.bnrcard.people .card-info .desc {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  text-overflow: ellipsis;
}
.box .box-text {
  text-align: center;
}
.bnrcard.hovercard {
  position: relative;
  padding-top: 0;
}
.bnrcard.hovercard .avatar {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100px;
}
.bnrcard.hovercard .avatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}
.avatar {
  width: 80px;
  height: 80px;
  float: left;
  margin-right: 15px;
}
.avatar-banner {
  width: 100%;
  height: auto;
  float: left;
  margin-left: 30%;
  max-width: 256px;
}
.img-container-profile {
  border-radius: 5px;
  margin-left: 10px;
  display: flex;
}
.avatar img {
  max-width: 100%;
  border-radius: 10px;
}
.listing-box .head p {
  text-align: left;
}
.bnrcard.hovercard .info {
  padding: 4px 8px 10px;
}
.btn-danger {
  background-color: #004aad !important;
  border-color :#004aad !important;
}
.btn-light {
  background-color:  rgba(0, 0, 0, 0.03) !important;
  border-color : rgba(0, 0, 0, 0.03)  !important;
}
/*.form-control {
  color: #bbb4b5 !important;
}*/
.bnrcard.hovercard .info .title {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #000;
  vertical-align: middle;
  margin-bottom: 10px;
  width: 100%;
  float: left;
}
.bnrcard.hovercard .info .title div {
  display: inline-block;
}
.bnrcard.hovercard .info .title a {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1;
  color: #000;
  vertical-align: middle;
}
.bnrcard.hovercard .info .desc {
  overflow: hidden;
  font-size: 16px;
  color: #000;
  text-overflow: ellipsis;
  display: inline-block;
  width: auto;
}

.box {
  padding: 20px 12px;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 350px;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
}
.box hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.box img {
  max-width: 100%;
}
.box-title {
  color: #428bca;
}
.box .fa {
  padding: 0 0 0 10px;
}
.challenges {
  margin-top: 20px;
}
.challenges h3 {
  padding: 10px 20px;
  border-left: 4px #004aad solid;
  display: block;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.challengebtn {
  font-size: 20px;
  display: inline;
  margin-top: 20px;
}

.ngx-pagination {
  color: #004aad !important;
  background: #fefefe !important;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.ngx-pagination li {
  border-right: 1px solid #dee2e6;
  font-size: 14px;
  padding: 4px;
}
.ngx-pagination li:last-child {
  border-right: none;
}
.current {
  color: #fefefe !important;
  background: #004aad !important;
}
.pagination-previous {
  color: #004aad !important;
}
.pagination-next {
  color: #004aad !important;
}
.wrap img {
  border-radius: 10px;
}
.m_container .nav-link.active {
  background: #004aad;
  padding: 10px !important;
  font-size: 14px;
  border-radius: 0px;
  border-left: 4px #162c4f solid;
  color: #fff !important;
  border-bottom: 1px #f4f4f4 solid;
}
.m_container .nav-link {
  background: #f9f9f9;
  padding: 10px !important;
  font-size: 14px;
  border-radius: 0px;
  border-bottom: 1px #f4f4f4 solid;
}
.brdr {
  border: 4px #004aad solid;
  border-radius: 10px;
  margin-left: 50px !important;
}
.selectMultiple {
  width: 100%;
  position: relative;
}
.selectMultiple select {
  display: none;
}
.selectMultiple > div {
  position: relative;
  z-index: 0;
  padding: 8px 12px 2px 12px;
  border-radius: 8px;
  background: #fff;
  font-size: 14px;
  min-height: 44px;
  box-shadow: 0 4px 16px 0 rgba(22, 42, 90, 0.06);
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.selectMultiple > div:hover {
  box-shadow: 0 4px 24px -1px rgba(22, 42, 90, 0.06);
}
.selectMultiple > div .arrow {
  right: 1px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  width: 28px;
  position: absolute;
}
.selectMultiple > div .arrow:before,
.selectMultiple > div .arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  height: 8px;
  border-bottom: 8px solid #99a3ba;
  top: 43%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.selectMultiple > div .arrow:before {
  right: 12px;
  -webkit-transform: rotate(-130deg);
  transform: rotate(-130deg);
}
.selectMultiple > div .arrow:after {
  left: 9px;
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
}
.selectMultiple > div span {
  color: #99a3ba;
  width: 100%;
  display: block;
  position: absolute;
  left: 12px;
  cursor: pointer;
  top: 8px;
  line-height: 28px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.selectMultiple > div span.hide {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-4px, 0);
  transform: translate(-4px, 0);
}
.selectMultiple > div a {
  position: relative;
  padding: 0 24px 6px 8px;
  line-height: 28px;
  color: #1e2330;
  display: inline-block;
  vertical-align: top;
  margin: 0 6px 0 0;
}
.selectMultiple > div a em {
  font-style: normal;
  display: block;
  white-space: nowrap;
}
.selectMultiple > div a:before {
  content: "";
  left: 0;
  top: 0;
  bottom: 6px;
  width: 100%;
  position: absolute;
  display: block;
  background: rgba(228, 236, 250, 0.7);
  z-index: -1;
  border-radius: 4px;
}
.selectMultiple > div a i {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 28px;
  display: block;
}
.selectMultiple > div a i:before,
.selectMultiple > div a i:after {
  content: "";
  display: block;
  width: 2px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #004aad;
  border-radius: 1px;
}
.selectMultiple > div a i:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.selectMultiple > div a i:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.selectMultiple > div a.notShown {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.selectMultiple > div a.notShown:before {
  width: 28px;
  -webkit-transition: width 0.45s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0.2s;
  transition: width 0.45s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0.2s;
}
.selectMultiple > div a.notShown i {
  opacity: 0;
  -webkit-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
}
.selectMultiple > div a.notShown em {
  opacity: 0;
  -webkit-transform: translate(-6px, 0);
  transform: translate(-6px, 0);
  -webkit-transition: all 0.4s ease 0.3s;
  transition: all 0.4s ease 0.3s;
}
.selectMultiple > div a.notShown.shown {
  opacity: 1;
  text-decoration: none;
}
.selectMultiple > div a.notShown.shown:before {
  width: 100%;
}
.selectMultiple > div a.notShown.shown i {
  opacity: 1;
}
.selectMultiple > div a.notShown.shown em {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.selectMultiple > div a.remove:before {
  width: 28px;
  -webkit-transition: width 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0s;
  transition: width 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0s;
}
.selectMultiple > div a.remove i {
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.selectMultiple > div a.remove em {
  opacity: 0;
  -webkit-transform: translate(-12px, 0);
  transform: translate(-12px, 0);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.selectMultiple > div a.remove.disappear {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease 0s;
  transition: opacity 0.5s ease 0s;
}
.selectMultiple > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  border-radius: 8px;
  -webkit-transform: translate(0, 20px) scale(0.8);
  transform: translate(0, 20px) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-filter: drop-shadow(0 12px 20px rgba(22, 42, 90, 0.08));
  filter: drop-shadow(0 12px 20px rgba(22, 42, 90, 0.08));
  -webkit-transition: all 0.4s ease,
    -webkit-transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44),
    -webkit-filter 0.3s ease 0.2s;
  transition: all 0.4s ease,
    -webkit-transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44),
    -webkit-filter 0.3s ease 0.2s;
  transition: all 0.4s ease,
    transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44), filter 0.3s ease 0.2s;
  transition: all 0.4s ease,
    transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44), filter 0.3s ease 0.2s,
    -webkit-transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44),
    -webkit-filter 0.3s ease 0.2s;
}
.selectMultiple > ul li {
  color: #1e2330;
  background: #fff;
  padding: 12px 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  -webkit-transition: background 0.3s ease, color 0.3s ease,
    opacity 0.5s ease 0.3s, border-radius 0.3s ease 0.3s,
    -webkit-transform 0.3s ease 0.3s;
  transition: background 0.3s ease, color 0.3s ease, opacity 0.5s ease 0.3s,
    border-radius 0.3s ease 0.3s, -webkit-transform 0.3s ease 0.3s;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease 0.3s,
    opacity 0.5s ease 0.3s, border-radius 0.3s ease 0.3s;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease 0.3s,
    opacity 0.5s ease 0.3s, border-radius 0.3s ease 0.3s,
    -webkit-transform 0.3s ease 0.3s;
}
.selectMultiple > ul li:first-child {
  border-radius: 8px 8px 0 0;
}
.selectMultiple > ul li:first-child:last-child {
  border-radius: 8px;
}
.selectMultiple > ul li:last-child {
  border-radius: 0 0 8px 8px;
}
.selectMultiple > ul li:last-child:first-child {
  border-radius: 8px;
}
.selectMultiple > ul li:hover {
  background: #004aad;
  color: #fff;
}
.selectMultiple > ul li:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(1, 1) translate(-50%, -50%);
  transform: scale(1, 1) translate(-50%, -50%);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.selectMultiple > ul li.notShown {
  display: none;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: opacity 0.4s ease, -webkit-transform 0.35s ease;
  transition: opacity 0.4s ease, -webkit-transform 0.35s ease;
  transition: transform 0.35s ease, opacity 0.4s ease;
  transition: transform 0.35s ease, opacity 0.4s ease,
    -webkit-transform 0.35s ease;
}
.selectMultiple > ul li.notShown.show {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.selectMultiple.open > div {
  box-shadow: 0 4px 20px -1px rgba(22, 42, 90, 0.12);
}
.selectMultiple.open > div .arrow:before {
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.selectMultiple.open > div .arrow:after {
  -webkit-transform: rotate(50deg);
  transform: rotate(50deg);
}
.selectMultiple.open > ul {
  -webkit-transform: translate(0, 12px) scale(1);
  transform: translate(0, 12px) scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-filter: drop-shadow(0 16px 24px rgba(22, 42, 90, 0.16));
  filter: drop-shadow(0 16px 24px rgba(22, 42, 90, 0.16));
}
.medium-space {
  margin-right: 140px;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(30, 30);
    transform: scale(30, 30);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(50, 50);
    transform: scale(50, 50);
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(30, 30);
    transform: scale(30, 30);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(50, 50);
    transform: scale(50, 50);
  }
}

.form-control:focus {
  border-color: #004aad;
  box-shadow: 0 0 0 0.2rem rgba(230, 0, 35, 0.25);
}

.choose_logo .btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #004aad;
  cursor: pointer;
  display: block;
}
.custom_choose_one {
  color: #004aad !important;
  font-size: 24px;
  font-weight: bold;
  padding: 0;
  margin: 0s;
  position: relative;
}
.profileicon {
  position: absolute !important;
  right: 15%;
  top: 10px;
}
#inputfile {
  margin: 0px;
  padding: 0px;
}
#blah {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100px;
  border: 4px #004aad solid;
}
#banner_img {
  max-width: 100%;
  max-height: 200px;
}
.form-control:focus {
  border-color: #004aad;
  box-shadow: 0 0 0 0.2rem rgba(230, 0, 35, 0.25);
}
.choose_logo .btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #004aad;
  cursor: pointer;
  display: block;
}
.custom_choose_one {
  color: #004aad !important;
  font-size: 24px;
  font-weight: bold;
  padding: 0;
  margin: 0s;
  position: relative;
}
#inputfile {
  margin: 0px;
  padding: 0px;
}
#blah {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100px;
  border: 4px #004aad solid;
}
#banner_img {
  max-width: 100%;
  max-height: 200px;
}

.account_card {
  background: #fff;
  width: 100%;
  padding: 20px 20px 20px;
  display: block;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.03);
}
.inputfile-box {
  position: relative;
}
.inputfile {
  display: none;
}
.file-box {
  display: inline-block;
  width: 100%;
  border: 1px solid;
  padding: 5px 15px;
  box-sizing: border-box;
  height: 36px;
}
.file-button {
  background: #004aad;
  padding: 6px 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
}

.table-wrapper {
  width: 100%;
}
.table-title {
  padding-bottom: 10px;
  margin: 0 0 10px;
}
.table-title h2 {
  margin: 6px 0 0;
  font-size: 22px;
}
.table-title .add-new {
  float: right;
  height: 30px;
  font-weight: bold;
  font-size: 12px;
  text-shadow: none;
  min-width: 100px;
  border-radius: 50px;
  line-height: 13px;
}
.table-title .add-new i {
  margin-right: 4px;
}
/* table.table { table-layout: fixed; } */
.table-wrapper table.table tr td {
  width: 30%;
}
table.table tr th,
table.table tr td {
  border-color: #e9e9e9;
}
table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}
table.table th:last-child {
  width: 100px;
}
table.table td a {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  min-width: 20px;
}
table.table td a.add {
  color: #27c46b;
}
table.table td a.edit {
  color: #ffc107;
}
table.table td a.delete {
  color: #e34724;
}
table.table td i {
  font-size: 19px;
}
table.table td a.add i {
  font-size: 24px;
  margin-right: -1px;
  position: relative;
  top: 3px;
}
table.table .form-control {
  height: 32px;
  line-height: 32px;
  box-shadow: none;
  border-radius: 2px;
}
table.table .form-control.error {
  border-color: #004aad;
}
table.table td .add {
  display: none;
}
.material-icons {
  font-family: "Material Icons";
}

.cdk-overlay-pane {
  background-color: #fff;
  /* border: 1px #ccc solid; */
  border-radius: 4px;
  /* padding: 20px; */
}

.cuppa-dropdown {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  padding: 0px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
  /*box-shadow: 1px 1px 7px 1px #004aad;*/
  box-shadow: 1px 1px 15px 0px #00000017;
  color: #bbb4b5;
}

#cat_center {
  width: 45%;
  margin: 0 auto;
}
#cat_center img {
  max-height: 24px;
  text-align: center;
  margin: 6px auto;
}
#cat_center .next-slide {
  width: 24px;
  height: 24px;
  float: left;
  padding: 25px 0;
}
#cat_center .prev-slide {
  width: 24px;
  height: 24px;
  float: left;
  padding: 25px 0;
}
#cat_center .next-slide:before {
  color: #004aad;
  font-size: 20px;
  font-weight: 700;
  content: "\f0a9";
  font-family: "Font Awesome 5 Free";
}
#cat_center .prev-slide:before {
  color: #004aad;
  font-size: 20px;
  font-weight: 700;
  content: "\f0a8";
  font-family: "Font Awesome 5 Free";
}
#cat_center .slick-slide {
  margin: 0 2px;
}
#cat_center button[type="button"] {
  box-shadow: none;
  top: 20px;
}
#cat_center button {
  cursor: pointer;
  width: auto;
  padding: 2px 12px 4px 10px;
  display: inline-block;
  border-radius: 50px;
  box-shadow: 1px 1px 15px 0px #00000017;
  border: none;
  outline: none;
  margin: 5px 10px;
  background: #fff;
  font-weight: 300;
  font-size: 14px;
  line-height: 38px;
  text-align: left;
}
#cat_center button img {
  margin-left: -10px;
  margin-right: 10px;
  padding-left: 10px;
  float: left;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

#cat_center button:hover {
  cursor: pointer;
  background-color: #004aad;
  color: White;
  overflow: hidden;
  transition-duration: 1s;
}
#cat_center .slick-dots button {
  background-color: #004aad;
  padding: 6px 1px 10px 10px;
}
#cat_center .slick-dots {
  position: relative !important;
  bottom: 0px;
}
#cat_center button.active {
  background: #004aad;
  color: #fff;
  box-shadow: 1px 1px 15px 1px #004aad33;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.5s ease;
  font-weight: 500;
  font-size: 14px;
}
#cat_center .slick-list {
  width: 100% !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.listbox-shad {
  text-align: center;
  padding: 20px;

  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}
.listbox-shad small {
  font-size: 13px;
  font-weight: 200;
}
/* .listbox-shad h5 {
} */
.listbox-shad.left {
  width: 30%;
  margin-right: 3%;
  position: relative;
}
.listbox-shad.right {
  width: 65%;
}
#accordion .card-header {
  background: #ccc !important;
  border-radius: 0 !important;
}
#headingOne {
  text-align: left;
}
#accordion .card-header,
#accordion .card-link,
#accordion .card-link:hover {
  background-color: transparent;
  text-align: left;
  color: #004aad;
}
#accordion .card-body {
  padding: 10px 10px;
}
#accordion .card {
  width: 100%;
}
#accordion p {
  position: absolute;
  display: inline;
  float: right;
  right: 16px;
  font-size: 14px;
  color: #000;
}
#Accordiontwo p {
  position: absolute;
  display: inline;
  float: right;
  right: 16px;
  font-size: 14px;
  color: #000;
}
.clr-n {
  color: #004aad;
}
.user_info p {
  font-size: 14px;
}
#accordion #menuone label {
  font-size: 13px;
}

@keyframes minus {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(2700deg);
  }
}
[aria-expanded="false"] > .expanded,
[aria-expanded="true"] > .collapsed {
  display: none;
}

.form-control-borderless {
  border: none;
}
.form-control-borderless:hover,
.form-control-borderless:active,
.form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.text-secondary {
  color: #6c757d !important;
}

:host ::ng-deep .multiselect-dropdown .dropdown-btn {
  float: left;
}
.multiselect-dropdown {
  /*box-shadow:  1px 1px 7px 1px #004aad;*/
  box-shadow: 1px 1px 15px 0px #00000017;
  color: #004aad;
}
#artwork-caret-dropdowns .multiselect-dropdown {
  color: #bbb4b5 !important;
}
#cat_center .slick-list {
  width: 100% !important;
  float: left !important;
}
.dropdown-btn .dropdown-multiselect__caret {
  padding: 30px 8px !important;
}
.post-assignment-skills .dropdown-btn .dropdown-multiselect__caret {
  padding: 23px 8px !important;
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0.75rem !important;
}
.multiselect-dropdown .selected-item {
  max-width: 100%;
}
.mat-cell .mat-icon-button {
  background: none;
}
#myTabContent {
  max-width: 98%;
  margin: 0 1%;
}
.column video {
  height: auto;
  display: block;
  margin: 0 auto;
}
.mat-icon-button {
  border-radius: 0px !important;
  /* background-color: #fff !important; */
}
.carousel2 {
  position: fixed;
  left: 0%;
  top: 45px;
}
.carousel__cell {
  position: absolute;
  width: 75%;
  padding: 20px;
  height: auto;
  left: 10px;
  top: 10px;
  transition: transform 1s, opacity 1s, left 1s;
  border-radius: 10px;
}
.carousel__cell:nth-child(9n + 1) {
  background-color: #ffffff;
  box-shadow: 0 0px 25px 0 rgba(0, 0, 0, 0.1), 0 0px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0);
  width: 80%;
  margin: 0 10% 0% 20%;
  height: auto;
}
.carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
  background: hsla(0, 0%, 100%, 0.8);
}

.product_carousel2 {
  position: fixed;
  width: 90%;
  left: 13%;
  top: 45px;
}
.product_carousel__cell {
  position: absolute;
  width: 100%;
  padding: 20px;
  height: 500px;
  left: 10px;
  top: 10px;
  transition: transform 1s, opacity 1s, left 1s;
  border-radius: 10px;
  background: #ffffff;
}
.product_carousel__cell_audio {
  background-image: url("./assets/images/audio.jpeg");
  background-size: cover;
  position: relative;
}
.product_carousel__cell_audio audio {
  position: absolute;
  bottom: 0;
  width: 80%;
}
.product_carousel__cell:nth-child(9n + 1) {
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 25px 0 #0000001a, 0 0 7px 0 #00000030;
}
.product_carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
  background: hsla(0, 0%, 100%, 0.8);
}
.product_carousel__cell img {
  max-width: 500px !important;
  max-height: 400px;
  margin: 40px auto;
  box-shadow: 0 5px 10px 0 rgba(114, 206, 229, 0.25);
  border-radius: 10px;
  background: #ffffff;
}
div iframe {
  border-radius: 5px;
  width: 100%;
  height: 463px;
}
.wrap img {
  width: 100%;
}
.wrap div iframe {
  border-radius: 20px;
  width: 100%;
  height: auto;
}
#clients div iframe {
  border-radius: 5px;
  width: 100%;
  height: 200px;
}
.otherProjectList div iframe {
  border-radius: 5px;
  width: 200px;
  height: 200px !important;
}
.zoom_icon input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
/* IMAGE STYLES */
[type="radio"] + img {
  cursor: pointer;
}

.loadWrapper {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
}
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3d3e3f; /* gray */
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.comment-body .comment-body {
  padding-left: 10%;
  margin-top: 10px;
}
.attribution a {
  color: #555;
  font-size: 14px;
  border: none;
  background: none;
}
.attribution a:hover {
  background: #004aad;
  color: #fff !important;
  font-size: 14px;
  border: none;
}
.detview {
  position: relative;
  display: block;
  left: 0;
  top: 0px;
}
#accordion .multiselect-dropdown .dropdown-btn .selected-item {
  max-width: inherit !important;
}
#accordion .dropdown-list ul {
  background: #444359;
  border-radius: 2px;
  margin: 0 auto;
}
#accordion .dropdown-list .multiselect-item-checkbox input[type="checkbox"] {
  background: #fff !important;
  border-radius: 3px;
}
#accordion .multiselect-item-checkbox div {
  color: #ffffff !important;
}
#accordion .multiselect-item-checkbox:hover {
  background: #004aad;
  color: #fff;
  font-size: 14px;
}
#accordion .dropdown-list .multiselect-item-checkbox {
  font-size: 14px;
}
#accordion .dropdown-list {
  padding-top: 0px !important;
}
#accordion .dropdown-btn {
  background-image: url("./assets/images/paint.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 28px 30px;
  border: none;
  position: relative;
  left: 10px;
  top: 7px;
  height: 40px;
}
#accordion .dropdown-btn span {
  margin-left: 36px;
  font-weight: 500;
  font-size: 14px;
}
#accordion .dropdown-btn span.selected-item-container {
  margin-left: 22px;
}
#accordion .dropdown-btn span.selected-item-container span.selected-item,
#accordion .dropdown-btn span.selected-item-container span.selected-item span {
  font-size: 9px;
  margin-left: 0;
}
#accordion .multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  border-color: transparent;
  height: 50px;
  padding: 3px;
  border-radius: 5px;
  color: #004aad;
  box-shadow: 0 3px 10px rgb(230 0 35 / 0.2);
}
#accordion .multiselect-dropdown .dropdown-btn .selected-item {
  background: #004aad !important;
  border: 1px solid #004aad !important;
}
#accordion .multiselect-dropdown .filter-textbox input {
  padding: 7px 15px;
  border-radius: 3px;
}
#accordion .pure-checkbox label {
  color: #ffffff !important;
}
#accordion .dropdown-btn .dropdown-multiselect__caret {
  padding: 5px 40px 8px 20px !important;
}
#accordion .multiselect-item-checkbox input[type="checkbox"] + div:before {
  border: none !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 7px !important;
  margin-top: -7px !important;
  background: #004aad !important;
}

#Accordiontwo .multiselect-item-checkbox input[type="checkbox"] + div:before {
  border: none !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 2px !important;
  margin-top: -7px !important;
  background: #004aad !important;
}
#artwork-caret-dropdowns .dropdown-btn .dropdown-multiselect__caret {
  padding: 6px 40px 8px 20px !important;
}

#Accordiontwo .multiselect-dropdown .dropdown-btn .selected-item {
  max-width: inherit !important;
}
#Accordiontwo .dropdown-list ul {
  background: #444359;
  border-radius: 2px;
  margin: 0 auto;
}
#Accordiontwo .dropdown-list .multiselect-item-checkbox input[type="checkbox"] {
  background: #fff !important;
  border-radius: 3px;
}
#Accordiontwo .multiselect-item-checkbox div {
  color: #ffffff !important;
}
#Accordiontwo .multiselect-item-checkbox:hover {
  background: #004aad;
  color: #fff;
  font-size: 14px;
}
#Accordiontwo .dropdown-list .multiselect-item-checkbox {
  font-size: 14px;
}
#Accordiontwo .dropdown-list {
  padding-top: 0px !important;
}
#Accordiontwo .dropdown-btn {
  background-image: url("./assets/images/list.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 28px 30px;
  border: none;
  position: relative;
  left: 10px;
  top: 7px;
  height: 30px;
}
#Accordiontwo .dropdown-btn span {
  margin-left: 36px;
  font-weight: 500;
  font-size: 14px;
}
#Accordiontwo .dropdown-btn span.selected-item-container {
  margin-left: 22px;
}
#Accordiontwo .dropdown-btn span.selected-item-container span.selected-item,
#Accordiontwo
  .dropdown-btn
  span.selected-item-container
  span.selected-item
  span {
  font-size: 9px;
  margin-left: 0;
}
#Accordiontwo .multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  border-color: transparent;
  height: 50px;
  padding: 3px;
  border-radius: 5px;
  color: #004aad;
  box-shadow: 0 3px 10px rgb(230 0 35 / 0.2);
}
#Accordiontwo .multiselect-dropdown .filter-textbox input {
  padding: 7px 15px;
  border-radius: 3px;
}
#Accordiontwo .multiselect-dropdown .dropdown-btn .selected-item {
  background: #004aad !important;
  border: 1px solid #004aad !important;
}
#Accordiontwo .pure-checkbox label {
  color: #ffffff !important;
}
#Accordiontwo .dropdown-btn .dropdown-multiselect__caret {
  padding: 5px 40px 8px 20px !important;
}
#Accordiontwo .multiselect-item-checkbox input[type="checkbox"] div :before {
  border: none !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 2px !important;
  margin-top: -7px !important;
}
.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 150px !important;
  background: #004aad !important;
}
.tablinks img {
  max-width: 50px;
}
.edit-title {
  font-size: 23px;
  text-transform: uppercase;
}
.ag-center-cols-container {
  width: 100% !important;
}
.center-nav .nav-item {
  margin: 0 auto !important;
}
.editprof {
  top: 30px;
}
.video_reel_cell {
  padding: 20px;
  left: 10px;
  top: 10px;
  transition: transform 1s, opacity 1s, left 1s;
  border-radius: 10px;
}
.video_reel_cell iframe {
  height: 350px;
}
.bottom_btn {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 5px;
}
.bottom_btn p a {
  color: #fff;
  margin-right: 30px;
  font-size: 16px;
}
.bottom_btn p {
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  text-align: center;
}
/*.bottom_btn a:hover { color: #004aad; text-decoration: none;}*/
.bottom_btn p .custom_choose_one {
  color: #fff !important;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  margin: 0s;
  position: relative;
  cursor: pointer;
}
.carousel__cell .box {
  width: 31%;
  float: left;
  margin: 0 10px 10px 0;
  height: 200px;
  padding: 10px 12px !important;
}
.carousel__cell .rotateturn {
  width: 100% !important;
  height: 500px;
}
.otherProjectList {
  width: 200px;
  height: 200px;
  float: left;
  margin: 5px;
  background: #ffffff url("./assets/images/gray-logo.png") repeat;
}
.custom-search {
  margin-top: 5px;
  padding: 0px;
}
.threeDIconContainer {
  width: auto;
}
.threeDIcon {
  width: 25px;
  transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(35deg);
  box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, 0.5),
    1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}
.threeDIcon:hover {
  transform: rotate3d(0, 0, 0, 60deg) rotate(120deg);
}
.description {
  padding: -1px 20px;
}
div iframe aside {
  display: none !important;
}
.user_img {
  height: 60px;
  width: 60px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 60px;
  width: 60px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 7px;
  background-color: #00000008;
  padding: 10px;
  position: relative;
  width: 75%;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  padding-left: 20px;
  border-radius: 7px;
  background-color: #c2dfff;
  padding: 10px;
  position: relative;
  width: 75%;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -25px;
  color: #808080 !important;
  font-size: 10px;
}
.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -25px;
  color: #808080 !important;
  font-size: 10px;
}
.input-group-append {
  position: relative;
}
.emojiWindow {
  position: absolute;
  right: 0;
  bottom: 120px;
}
.chat-container {
  max-width: 95%;
}
.users-list-action {
  margin: 20px;
  border-radius: 50%;
  width: 25px;
  text-align: center;
  color: #fff;
  background-color: #004aad;
}
.global-new-message-count {
  border-radius: 50%;
  color: #fff;
  background-color: #004aad;
  padding: 2px;
}
/* .btn-primary {
  padding: 0.375rem 0.75rem !important;
  font-size: 16px !important;
} */

.listing-box a .text-left {
  color: #004aad;
  font-size: 17px;
  font-weight: 500;
  margin: 0px;
}

.listing-box .description {
  text-align: initial;
  padding: 0px;
}
#slider_desc_toogler {
  border-top: rgb(150, 149, 149) 1px dotted;
  margin-bottom: 30px;
  margin-top: -15px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

#slider_desc_toogler i {
  position: absolute;
  text-align: center;
  color: silver;
  font-size: 25px;
  font-family: fontawesome;
  left: calc(50% - 10px);
  margin-top: -13px;
  background: #fff;
}
.badge a {
  text-decoration: none;
  color: #004aad;
  background: white;
}
.badge-light {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 600;
}
.btn-outline-dangers {
  border: 1px solid #004aad;
  color: #004aad;
}
.btn-outline-danger:hover {
  color: #004aad;
  background-color: white;
  border-color: #004aad;
}

.btn-outline-danger:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
/* body {
  padding: 10px;
} */

#exTab1 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

#exTab2 h3 {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

/* remove border radius for the tab */

#exTab1 .nav-pills > li > a {
  border-radius: 0;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills > li > a {
  border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

.modal.show .modal-dialog {
  transform: none;
}
.progress-bar {
  background-color: #004aad;
}
@media only screen and (max-width: 1200px) {
  .navup1 {
    display: none;
  }
  .navup {
    display: contents;
  }
  .custom-search {
    margin-top: 5px;
    padding: 0px;
  }
  .navbar-brand img {
    height: 40px;
    margin-right: 20px;
  }
  .header_p .navbar-brand {
    margin: 0;
    padding: 0;
  }
  .header_p .nav-item {
    padding: 12px 0;
    margin: 0 5px 0 100px !important;
  }
  .menubtn {
    font-size: 12px;
    margin: 15px 0;
  }
  .signin {
    margin-left: 10px;
    padding: 5px 12px;
    height: 34px;
    margin: 10px 0 0 10px;
  }
  .bnr p {
    width: 70%;
  }
  .btwoc {
    width: 992px;
  }
  .btwoc-tile {
    width: 130px;
  }
  .btwoc-tile span {
    font-size: 12px;
  }
  .steps_detail {
    width: 350px;
    margin-left: 10px;
  }
  .clr_two {
    background: #8c9df4;
    margin-left: 50px;
  }
  .clr_three {
    background: #3cbbdb;
    margin-left: 80px;
  }
  .clr_four {
    background: #ff974f;
    margin-left: 50px;
  }
  .tab button {
    font-size: 12px;
  }
  .tab button img {
    max-width: 40px;
  }
  .tab button {
    box-shadow: 1px 1px 10px 0px #00000017;
    margin: 10px 5px;
  }
  .tab button.active {
    box-shadow: 1px 1px 10px 0px #004aad33;
    margin: 10px 5px;
  }
  .accordiontwo .linktwo {
    font-size: 14px;
  }
  .accordion .link {
    font-size: 14px;
  }
  .accordiontwo .linktwo img {
    max-width: 24px;
  }
  .accordiontwo .linkt img {
    max-width: 24px;
  }
  .accordiontwo {
    max-width: 180px;
  }
  .accordion {
    max-width: 180px;
  }
  .zoom {
    height: 320px;
  }
  .product__slider-box {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 550px;
  }
  .product__slider-box img {
    max-width: 500px;
    border-radius: 20px;
  }
  .searchbar {
    margin-right: 20px;
  }
  .icon_ipload {
    margin: 0 10px;
    width: 22%;
  }
  .btntag {
    padding: 2px 5px;
    font-size: 12px;
  }
  #cat_center {
    width: 65%;
    float: left;
  }
  .grid-item span.auth h5 {
    font-size: 14px;
  }
  .grid-item span.auth small {
    font-size: 12px;
  }

  .btm_btn a {
    font-size: 12px;
    margin: 0 1px;
  }
  #cat_center button img {
    max-height: 24px;
  }
  #cat_center button {
    font-size: 14px;
    line-height: 38px;
    display: inline-flex;
    padding: 2px 9px 2px 9px;
  }
  #Accordiontwo .dropdown-btn span {
    margin-left: 25px;
    font-weight: 500;
    line-height: 15px;
    font-size: 13px;
  }
  #accordion .dropdown-btn span {
    margin-left: 25px;
    font-weight: 500;
    line-height: 15px;
    font-size: 13px;
  }
  .multiselect-dropdown .dropdown-btn {
    padding: 2px 9px !important;
  }
  #Accordiontwo .dropdown-btn {
    background-size: 22px 24px;
    height: 30px;
  }
  #accordion .dropdown-btn {
    background-size: 22px 24px;
    height: 30px;
  }
  #accordion .dropdown-btn span,
  #Accordiontwo .dropdown-btn span {
    margin-left: 15px;
  }
  /*#Accordiontwo .multiselect-dropdown{ height: 50px; }
  #accordion .multiselect-dropdown{ height: 50px;}*/
  .bottom_btn p a {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .medium-space {
    margin-right: 70px;
  }
  .brdr {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
  .custom-search {
    margin-top: 5px;
    padding: 0px;
  }
  .navbar-brand img {
    height: 40px;
    margin-right: 0px;
  }
  .header_p .nav-item {
    padding: 0;
    margin: 0 5px !important ;
  }
  .header_p .container {
    width: 100%;
    max-width: 100%;
  }
  .header_p .nav-item {
    padding: 12px 0;
    margin: 0;
  }
  .nav-link {
    font-size: 14px;
    padding: 10px 0 0 0px !important;
  }
  .menubtn {
    font-size: 10px;
  }
  .signin {
    margin: 10px 0 0 10px;
    height: 34px;
    padding: 5px 12px;
  }
  .tab button {
    padding: 6px 5px 6px 10px;
    box-shadow: 1px 1px 10px 0px #00000017;
    border: none;
    outline: none;
    margin: 10px 10px;
    background: #fff;
    font-weight: 500;
    font-size: 14px;
  }
  .tab button img {
    max-width: 40px;
  }
  .submenu li a {
    font-size: 16px;
  }
  .accordiontwo .linktwo img {
    max-width: 18px;
  }
  .accordiontwo .linktwo {
    font-size: 12px;
  }
  .accordion .link img {
    max-width: 18px;
  }
  .accordion .link {
    font-size: 12px;
  }
  .accordiontwo {
    max-width: 160px;
  }
  .accordion {
    max-width: 140px;
  }
  .tab {
    width: 100%;
    position: relative;
  }
  .grid .column {
    width: 48%;
    margin: 6px;
    float: left;
    vertical-align: top;
  }
  .submenu li a {
    font-size: 14px;
  }
  .submenu li {
    margin: 0 5px;
  }
  .zoom {
    height: 280px;
  }
  .product__slider-box {
    max-width: 400px;
  }
  .product__slider-box img {
    max-width: 350px;
  }
  .navbar-nav span,
  a {
    padding-left: 0px !important ;
  }
  .icon_ipload {
    margin: 0 4px;
    width: 23%;
  }
  .icon_ipload h5 {
    font-size: 12px;
  }
  .center-nav {
    margin: 0 auto 0px;
    max-width: 538px;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 7px 10px !important;
    z-index: 9999;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 7px 10px !important;
  }

  /*#cat_center .next-slide{ right: -40px;}*/
  .product {
    height: 80vh;
    min-height: 80vh;
  }
  .profabout h5 {
    font-size: 14px;
  }
  .profabout h5:hover {
    font-size: 14px;
  }
  .pagcent .page-link {
    padding: 5px 10px !important;
  }
  .btntag {
    padding: 4px 8px !important;
  }
  #cat_center {
    width: 100%;
    float: left;
  }
  #cat_center .slick-list {
    width: 100% !important;
  }
  .wid_drop {
    width: 25% !important;
  }
  .wid_drop #accordion {
    max-width: 170px;
  }
  .artist img {
    max-width: 210px;
    margin: 0 auto;
    display: block;
  }
  .video_reel_cell {
    max-width: 100%;
    height: 250px;
  }
  .carousel__cell {
    padding: 20px;
    top: 0px;
    height: 350px;
    width: 90% !important;
    left: -18% !important;
  }
  .carousel__cell .rotateturn {
    padding: 5px;
  }
  .product_carousel__cell img {
    max-width: 320px !important;
    max-height: 320px;
  }
  .product_carousel2 {
    left: 0;
    width: 100%;
    height: 400px;
  }
}

@media only screen and (max-width: 576px) {
  .custom-search {
    margin-top: 5px;
    padding: 0px;
  }
  .btwoc {
    width: 480px;
  }
  .custom-search {
    margin-top: 5px;
    padding: 0px;
  }
  .grid .column {
    width: 47%;
  }
  .icon_ipload {
    width: 43%;
  }
  .grid-item span.auth h5 {
    font-size: 14px;
    margin-bottom: -1px;
  }
  .grid-item span.auth small {
    font-size: 12px;
  }
  .viewsheart {
    font-size: 12px;
  }

  .portedit {
    right: 5px;
    top: 5px;
  }
  #contain h1 {
    font-size: 24px;
  }
  #contain h3 {
    font-size: 24px;
  }
  #contain p {
    font-size: 14px;
    line-height: 16px;
  }
  #contain span {
    font-size: 14px;
    line-height: 16px;
  }
  #contain .social-contain {
    margin: 2px 0;
  }
  .contain {
    min-height: 450px;
  }
  #contain .account input {
    padding: 6px 15px 6px 7px;
    margin: 4px 0;
    font-size: 10px;
  }
  #contain .account button {
    padding: 2px 25px;
    font-size: 12px;
  }
  #contain button.ghost {
    padding: 2px 25px;
    font-size: 12px;
  }
  .product {
    height: 50vh;
    margin-top: 30px;
  }
  .profabout h5 {
    font-size: 14px;
  }
  .profabout h5:hover {
    font-size: 14px;
  }
  .wrap-drop {
    float: left !important;
    margin: 0 15px !important;
    max-width: 190px;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    width: 100% !important;
  }
  #cat_center .slick-slide {
    margin: 0 12px;
    padding: 0 10px;
    height: 55px;
  }
  #cat_center button img {
    max-height: 20px;
    margin-right: 5px;
  }
  #cat_center button {
    line-height: 30px;
    height: 42px;
    width: 100%;
    font-size: 12px;
  }
  #cat_center button.active {
    font-size: 12px;
  }
  .wid_drop {
    width: 40% !important;
  }
  .wid_drop #accordion {
    max-width: 170px;
  }
  .product_carousel__cell img {
    max-width: 260px !important;
    margin: 26px auto;
    height: 310px !important;
  }
  .contain {
    margin: 10px auto;
  }
  .bottom_btn {
    left: 0px;
    width: 90% !important;
  }
  .ngx-pagination.responsive .small-screen {
    display: none !important;
  }
  .ngx-pagination.responsive
    li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
    display: inline-block !important;
  }
  #Accordiontwo .multiselect-dropdown {
    height: 60px;
  }
  #accordion .multiselect-dropdown {
    height: 60px;
  }
}

@media only screen and (max-width: 480px) {

  #map {
    width: 100vh !important;
  }
  #map2 {
    width: 100vh !important;
  }
  .map-text{
    width: 100vh !important;
  }
  #cat_center button {
    font-size: 16px !important;
    padding-top: 6px;
  }
  #Accordiontwo .dropdown-btn .dropdown-multiselect__caret {
    padding: 0px 5px 10px 0 !important;
  }
  #accordion .dropdown-btn .dropdown-multiselect__caret {
    padding: 0px 5px 10px 0 !important;
  }
  #Accordiontwo .multiselect-dropdown {
    height: 43px;
  }
  #Accordiontwo .dropdown-btn span {
    margin-left: 22px;
  }
  #accordion .multiselect-dropdown {
    height: 43px;
  }
  #accordion .dropdown-btn span {
    margin-left: 22px;
  }
  .wlcfont {
    font-size: 30px;
  }
  .avatar-banner {
    margin-left: 10% !important;
  }
  .artwork-head {
    text-align: center;
  }
  .samllartworktags {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .otherProjectList {
    float: none;
    margin: 10px auto;
  }
  .artworkview-headings {
    text-align: center;
  }
  .artwork-head .btntag {
    float: none;
  }

  .btwoc {
    width: 360px;
  }
  .custom-search {
    margin-top: 5px;
    padding: 0px;
  }
  .btwoc-tile {
    width: 130px;
  }
  .btwoc-tile span {
    font-size: 12px;
  }
  .navbar-brand img {
    height: 32px;
  }
  .grid .column {
    width: 98%;
  }
  .zoom {
    height: 400px;
  }
  .product__slider-box {
    max-width: 300px;
  }
  .product__slider-box img {
    max-width: 250px;
  }
  .searchbar:hover > .search_input {
    width: 255px;
  }
  .center-nav {
    margin: 0 auto 0px;
    max-width: 400px;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 2px 5px !important;
    font-size: 11px;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 2px 5px !important;
    font-size: 11px;
  }
  #contain .overlay-panel {
    padding: 0 5px;
  }
  #contain form.account {
    padding: 0 5px;
  }
  #contain h1 {
    font-size: 16px;
    font-weight: 700;
  }
  #contain h3 {
    font-size: 16px;
    font-weight: 700;
  }
  #contain p {
    font-size: 12px;
    line-height: 16px;
  }
  #contain span {
    font-size: 12px;
    line-height: 16px;
  }
  #contain .social-contain {
    margin: 2px 0;
  }

  .social-contain a {
    width: 30px;
    height: 30px;
  }
  #contain a {
    font-size: 12px;
  }
  .product {
    height: 50vh;
    margin-top: 30px;
  }
  .wrap-drop {
    float: left !important;
    margin: 0 5px !important;
    max-width: 170px;
  }
  .carousel__cell .box {
    width: 43%;
    float: left;
    margin: 0 20px 20px 0;
    height: 240px;
  }
  #cat_center .slick-slide {
    margin: 0 95px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px) {
  #cat_center {
    width: 60%;
    margin: 0 auto;
  }
  .grid .column {
    width: 24.4%;
    margin-right: 9px;
  }
  .navup1 {
    display: none;
  }
  
}
/*@media only screen and (max-width: 1380px) {
  .navup {
    display: none;
  }
}*/
@media only screen and (max-width: 768px) {

  .update-profile {
    width: 70%;
    margin: 0 auto;
  }
  .custom-search {
    margin-top: 5px;
    padding: 0px;
  }
  .navup1 {
    display: none;
  }
  .navup {
    display: contents;
  }
  .togglemenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: 5px;
    left: 0px;
  }
  .togglemenu li {
    margin: 0 5px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .nav-item:after {
    display: none;
  }
  .nav-link {
    padding: 4px 0 0 0px !important;
  }

  .signin {
    display: none;
  }
  .bnr p {
    width: 100%;
  }
  .navigation-wrap {
    position: fixed;
    padding: 23px;
  }
  .btwoc {
    width: 576px;
  }
  .header_p {
    height: 85px;
  }
  .header_p .nav-item {
    padding: 0;
  }
  .clr_two {
    background: #8c9df4;
    margin-left: 20px;
  }
  .clr_three {
    background: #3cbbdb;
    margin-left: 20px;
  }
  .clr_four {
    background: #ff974f;
    margin-left: 20px;
  }
  .center_cont {
    text-align: center;
  }
  .navbar-brand img {
    height: 40px;
  }
  .zoom img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
  .zoom .zoom-thumb a img {
    width: auto;
  }
  .zoom-main,
  .zoom-thumb a {
    float: none !important;
  }
  .zoom-main {
    height: auto !important;
  }
  .zoom .zoom-thumb {
    display: block;
    height: 100%;
    text-align: center !important;
    padding: 5px;
  }
  .zoom-thumb a {
    height: 20% !important;
    display: inline-block !important;
  }
  .grid .column {
    width: 47%;
  }
  .tab button {
    margin: 10px 4px;
  }
  .zoom {
    height: 500px;
  }
  .product__slider-box {
    max-width: 350px;
  }
  .product__slider-box img {
    max-width: 300px;
  }
  .product {
    height: 80vh;
    min-height: inherit;
  }
  .searchbar {
    margin: 0px;
  }
  .m_container {
    padding: 10px 30px;
  }
  .icon_ipload {
    margin: 0 0px 20px;
    width: 100%;
  }
  #myTab .nav-item::before {
    height: 0px;
  }
  .center-nav {
    margin: 0 auto 0px;
    max-width: 448px;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 5px 7px !important;
    font-size: 12px;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 5px 7px !important;
    font-size: 12px;
  }
  #contain .overlay-panel {
    padding: 0 20px;
  }
  #contain form.account {
    /* margin-left: 60px !important; */
    padding: 0 20px;
  }
  .product {
    height: 50vh;
    margin-top: 30px;
  }
  .profabout h5 {
    font-size: 12px;
  }
  .profabout h5:hover {
    font-size: 12px;
  }
  .btntag {
    padding: 4px 6px !important;
  }
  #cat_center button {
    margin: 5px;
  }
  .product_carousel__cell {
    position: relative !important;
  }
  .profileicon {
    right: 20% !important;
  }
  .m_container .nav-link,
  .m_container .nav-link.active {
    padding: 5px !important;
    font-size: 12px;
  }
  .carousel2 {
    height: 500px;
    left: 0;
  }
  .carousel__cell {
    width: 100% !important;
    margin: 0 0% !important;
    left: 0 !important;
  }
  .artist img {
    margin: 0 auto 40px;
  }
  .product_carousel__cell:nth-child(9n + 1) {
    left: 0 !important;
    position: absolute !important;
    height: 400px;
    left: inherit !important;
    width: 100% !important;
  }
  .bottom_btn {
    left: 0px;
    margin: 0 auto;
    width: 90%;
    bottom: 42px;
  }
  .bottom_btn p a {
    margin-right: 20px;
    margin-left: 20px;
  }
  div iframe {
    height: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .otherProjectList {
    margin: 10px auto;
  }
  .avatar-banner {
    margin-left: 9% !important;
  }
  .custom-search {
    padding: 0px;
  }
  .icon_ipload {
    width: 100%;
  }
  .center-nav {
    margin: 0 auto 0px;
    max-width: 330px;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 2px 5px !important;
    font-size: 11px;
    z-index: 0;
  }
  #mytab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 2px 5px !important;
    font-size: 11px;
    z-index: 0;
  }
  .wrap-drop {
    float: left !important;
    margin: 0 5px !important;
    max-width: 150px;
  }
  .submenu li a {
    font-size: 12px;
  }
  .product {
    height: 45vh;
    margin-top: 30px;
  }
  .btntag {
    padding: 4px !important;
  }
  .profabout h5 {
    font-size: 12px;
  }
  .profabout h5:hover {
    font-size: 12px;
  }
  .carousel__cell .box {
    width: 43%;
    float: left;
    margin: 0 20px 20px 0;
    height: 240px;
  }
  #cat_center .slick-slide {
    margin: 0 95px 0 0;
  }
}
.mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
  font: 400 16px / 20px Roboto , "Roboto", sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  letter-spacing: normal;
  
}
.mat-body p, .mat-body-1 p, .mat-typography .mat-body p, .mat-typography .mat-body-1 p, .mat-typography p {
  margin: 0 0 0px !important;
}
.description-textarea {
  width: 100%;
  height: 130px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #a5a0a0;
  background-color: white; 
  transition: border-color 0.3s ease;
  /* border-color: gray; */
}

.description-textarea:focus {
  border: 2px solid #004aad;
  border-color: #004aad; 
  outline: none; 
  background-color: white; 
}
.text-danger{
  font-size: 13px;
}
.description-textarea.border-error {
  border-color: red; /* Border turns red when there's an error */
}

/* @media (max-width: 768px) {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.25em;
    margin-top: 20% !important;
}
} */
.font1{
  font-family: 'Roboto', sans-serif;
}
.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: transparent;
  -webkit-text-fill-color: transparent;
  transition: none;
  display: block;
  font-family: 'Roboto', sans-serif;
}
.mat-typography h2 {
  font: 500 20px / 32px Roboto, " Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-radio-label-content {
  font-family: 'Roboto', sans-serif;
  font-size: 16px !important;
}
.mat-checkbox-layout .mat-checkbox-label {
  font-family: 'Roboto', sans-serif;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #004aad !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: #004aad !important;
}

.mat-checkbox-checked .mat-checkbox-checked .mat-checkbox-checkmark {
  color: white !important; 
}
.mat-checkbox-checked .mat-checkbox-frame {
  border-color: #004aad !important; 
}
.mat-checkbox:hover .mat-checkbox-background {
  background-color: rgba(0, 74, 173, 0.1);
}
.sb-text{
  font-weight: 500 !important;
}
.onlyhover:hover{
  background-color: #0c67c8 !important; 
  /* border: #0c67c8 !important; */
  color: white !important;
}